import React, { useState, useCallback, useEffect } from "react";
import { Redirect } from "react-router-dom";
import classNames from "./payment.module.css";
import Typography from "../../components/ui/typography";
import Button from "../../components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { purchase as purchaseAction } from "../../store/actions/auth";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import { checkPurchaseWithUserId } from "../../store/actions/auth";
import { GET_FILTERED_USERS, PURCHASE } from "../../store/actionTypes";
import Loading from "../../components/ui/loading/loading";
import { CHECK_PURCHASE_WITH_USER_ID } from "../../store/actionTypes";

const Payment = (props) => {
  window.sessionStorage.setItem("USER_VISITED_THE_PAYMENT_PAGE", true);

  const user = useSelector((state) => state.auth && state.auth.user);
  const selectedStudentsForMultiplePayment = useSelector(
    (state) => state.generic && state.generic.selectedStudentsForMultiplePayment
  );
  const numberOfStudents = selectedStudentsForMultiplePayment?.length || 0;
  console.log(
    "selectedStudentsForMultiplePayment: ",
    selectedStudentsForMultiplePayment
  );
  const dispatch = useDispatch();

  const active_products_temp =
    useSelector(
      (state) => state.auth.user && state.auth.user.active_products
    ) || [];
  const active_products = active_products_temp.sort((p1, p2) => {
    return p1.id > p2.id;
  });

  const applied_products = useSelector(
    (state) => state.auth.user && state.auth.user.applied_products
  );

  // const filtered_users = useSelector((state) => state.generic.filtered_users);
  const loadingSomething = useSelector(
    (state) =>
      state.loading[GET_FILTERED_USERS] ||
      state.loading[PURCHASE] ||
      state.loading[CHECK_PURCHASE_WITH_USER_ID]
  );

  const [selectedProducts, setSelectedProducts] = useState([40]); // 40 numaralı product 2023-24 tozoş için oluşturulmuş product id'dir. Artık seçmek yok, her yıl tek product seçiliyor.

  useEffect(() => {
    if (user && user.id) {
      if (user.role === 0) {
        // bazıları ödeme yaparken callback esnasında sorun oluyor, ödeme alınmasına rağmen sisteme düşmüyor. Bu sayfaya her girişte bi kez daha kontrol yapalım.
        dispatch(checkPurchaseWithUserId(user.id));
      }
    } else alert("Lütfen oturum açınız.");
  }, []);

  const purchase = useCallback(() => {
    return dispatch(
      purchaseAction(selectedProducts, selectedStudentsForMultiplePayment)
    );
  }, [dispatch, selectedProducts, selectedStudentsForMultiplePayment]);

  if (!user) return <Redirect to="/" />;
  if (user.role == 1 && numberOfStudents === 0) {
    alert("Lütfen öğrenci seçiniz");
    return <Redirect to="/yonetim" />;
  }
  if (applied_products.length > 0) return <Redirect to="/yonetim" />;

  // bir bilgisayarda session başlattıktan sonra başka bilgisayardan ödeme yapılırsa ilk tarafta ödeme gözükmeyecek, 2. kez ödemeye izin verecek.
  // Bunu engellemek için user ne zaman payment sayfasına gelse bi kez daha is_paid==true olan receipt var mı diye kontrol edelim:
  // if (filtered_users.length > 0 && filtered_users[0].is_paid){
  //   console.log("user: ", user);
  //   console.log("filterd_user: ", filtered_users);
  //   return <Redirect to="/" />;
  // }
  //if (loadingSomething) return <Loading />;

  const isSelected = (productId) => selectedProducts.includes(productId);
  const isApplied = (productId) =>
    !!applied_products.find((product) => product.id === productId);

  const toggleSelect = (productId) =>
    setSelectedProducts(
      isSelected(productId)
        ? selectedProducts.filter((id) => id !== productId)
        : // : [...selectedProducts, productId]
          [productId]
    );
  const onPurchase = () =>
    purchase().then((res) => {
      console.log(res);
      if (res && res.redirect_url) {
        window.location.href = res.redirect_url;
      } else if (res && res.error && res.error === "zaten satın alınmış") {
        //TODO: Bu kısmı öğretmene göre düzenle.
        // Binde 1 ihtimalle ödeme sayfası biyerde açıkken başka bilgisayardan da ödeme yapıldıysa bu uyarı verilecek.
        dispatch(checkPurchaseWithUserId(user.id));
        alert(
          "Daha önceden ödeme yapılmış. Yarışma sayfasına yönlendirileceksiniz..."
        );
      } else if (res && res.error) {
        alert(res.error);
      } else alert("İşlem başarısız. Hata kodu: 4987611");
    });

  const generateProductBox = (product) => {
    return (
      <div className={classNames.button_container} key={product.id}>
        {isApplied(product.id) && (
          <Typography color="lime" font="merel" size="small" weight="bold">
            Ödendi
          </Typography>
        )}
        <Button
          key={product.id}
          size="larger"
          background={
            isApplied(product.id)
              ? "lime"
              : isSelected(product.id)
              ? "orange"
              : "white"
          }
          style={{ width: "100%", padding: 8 }}
          onClick={() => toggleSelect(product.id)}
          disabled={isApplied(product.id)}
        >
          <Typography size="smaller" font="merel">
            {product.title}
          </Typography>
          <Typography
            size="smaller"
            font="merel"
            color={!isSelected(product.id) ? "grey" : "black"}
          >
            ({product.description})
          </Typography>
        </Button>
      </div>
    );
  };

  const totalFee = (
    <div>
      <Typography color="orange" font="merel" weight="bold" size="normal">
        Ödeme: <span className="grey">Kredi/Banka Kartı</span>
      </Typography>
      <Typography color="orange" font="merel" weight="bold" size="smaller">
        Toplam:
        <span className="white"> {selectedProducts.length * 50} TL </span>
        <span className="grey"> (KDV Dahil) </span>
      </Typography>
    </div>
  );

  const purchaseEndedWarning = (
    <Typography font="merel" size="large" weight="normal" color="orange">
      Şampiyona kayıt aşaması sona ermiştir.
    </Typography>
  );

  const headerRow = (
    <Typography font="merel" size="normal" weight="bold" color="orange">
      Ödeme
    </Typography>
  );
  const explanation = user.role === 0 && (
    <Typography font="tadao" color="white" size="smaller">
      <p>{"Sayın " + user.name}</p>
      Şampiyonaya katılabilmeniz için kayıt süresi sona ermeden önce katılım
      ücretinin ödenmesi gerekmektedir. Katılım ücreti her öğrenci için KDV
      dahil 300 TL'dir. (Katılım ücreti dışında sınavlar için başka bir ücret
      alınmayacaktır.)
    </Typography>
  );

  const explanationForUnclassifiedUser = user.role === 0 && (
    <Typography font="tadao" color="white" size="smaller">
      <p>{"Sayın " + user.name}</p>
      Ödeme yapmadan önce lütfen sınıfınızı seçiniz. Aşağıdaki bağlantıya
      tıklayınız ve açılan profil sayfanızda 'Düzenle' butonuna basarak
      sınıfınızı seçiniz.
    </Typography>
  );

  const explanationForCoach = user.role === 1 && (
    <Typography font="tadao" color="white" size="smaller">
      <p>{"Sayın " + user.name}</p>
      <p>
        Şampiyona katılım ücreti her öğrenci için KDV dahil 300 TL'dir. (Katılım
        ücreti dışında sınavlar için başka bir ücret alınmayacaktır.)
      </p>
      <p>
        {"Seçilen " +
          numberOfStudents +
          " öğrenci için toplam " +
          numberOfStudents * 300 +
          " TL katılım ücretini ödemek için aşağıdaki bağlantıya tıklayınız."}
      </p>
    </Typography>
  );

  const productList = (
    <>
      <div className={classNames.product_container}>
        {active_products
          .slice(0, 2)
          .map((product) => generateProductBox(product))}
      </div>
      <div className={classNames.product_container}>
        {active_products
          .slice(2, 6)
          .map((product) => generateProductBox(product))}
      </div>
      <div className={classNames.product_container}>
        {active_products
          .slice(6, 100)
          .map((product) => generateProductBox(product))}
      </div>
    </>
  );
  const purchaseButton = (
    <div
      className={classNames.check_out_container}
      style={{ marginTop: "30px" }}
    >
      {/* {totalFee} */}
      {selectedProducts.length > 0 && (
        <Button onClick={onPurchase}>Ödeme Adımına İlerle</Button>
      )}
    </div>
  );

  const goToProfileButton = (
    <div
      className={classNames.check_out_container}
      style={{ marginTop: "30px" }}
    >
      <Button onClick={() => props.history.push("/yonetim")}>
        Profil Sayfasına Git
      </Button>
    </div>
  );

  return (
    <div>
      {loadingSomething && <Loading />}
      <div className={"card " + classNames.payment}>
        {/* {purchaseEndedWarning} */}
        {headerRow}
        {user.role === 0 && !user.class_id && explanationForUnclassifiedUser}
        {user.role === 0 && user.class_id && explanation}
        {user.role === 1 && explanationForCoach}
        {/* {productList} */}
        {user.role === 0 && !user.class_id && goToProfileButton}
        {(user.role === 1 || (user.role === 0 && user.class_id)) &&
          purchaseButton}

        <ScrollToTop />
      </div>
    </div>
  );
};

export default Payment;
